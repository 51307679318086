/* global photoIds */

import $ from 'jquery'
import bootbox from 'bootbox'
import { clamp, isEmpty } from 'ramda'
import { baseUrl } from '../../../common/constants.js'
import { xhrPost } from '../../../helpers/http.js'

const PHOTO_STATUS_APPROVED = 2
const PHOTO_STATUS_FEATURED = 5

const POST_STATUS_APPROVED = 1
const POST_STATUS_DELETED = 2

const blockButtons = () => {
  document.querySelectorAll('.photo-acceptance-btn').forEach((btn) => {
    btn.disabled = true
  })
}

function pictureready(photoid) {
  blockButtons()

  $.ajax({
    url: '/admin/photostatus',
    type: 'post',
    dataType: 'json',
    data: { photoid: photoid, status: PHOTO_STATUS_APPROVED },
    success: function () {
      document.location.reload(true)
    }
  })
}

function picturefeature(photoid) {
  blockButtons()

  $.ajax({
    url: '/admin/photostatus',
    type: 'post',
    dataType: 'json',
    data: { photoid: photoid, status: PHOTO_STATUS_FEATURED },
    success: function () {
      document.location.reload(true)
    }
  })
}

function picturereadyall(key) {
  const maxlen = clamp(0, photoIds.length, key + 3)
  const photoidall = photoIds.slice(0, maxlen)

  if (isEmpty(photoidall)) {
    return
  }

  blockButtons()

  $.ajax({
    url: '/admin/photostatusall',
    type: 'post',
    dataType: 'json',
    data: { photoidall: photoidall, status: PHOTO_STATUS_APPROVED },
    success: () => {
      document.location.reload(true)
    }
  })
}

const setAcceptance = async (btn, userid, status) => {
  btn.disabled = true
  btn.querySelector('.loading').classList.remove('hidden')

  try {
    await xhrPost(`${baseUrl}/admin/set-acceptance`, {
      userid,
      status
    })
    document.location.reload(true)
  } catch (e) {}
}

const manuallyConfirm = async (btn, userid) => {
  btn.disabled = true
  btn.querySelector('.loading').classList.remove('hidden')

  try {
    await xhrPost(`${baseUrl}/admin/manually-confirm`, {
      userid
    })
    document.location.reload(true)
  } catch (e) {}
}

function picturedelete(photoid) {
  const dialog = bootbox.prompt({
    title: ' Delete voucher',
    message: '<p>Please select an option below:</p>',
    inputType: 'radio',
    inputOptions: [
      {
        text: 'Does not meet company conditions',
        value: 'Does not meet company conditions'
      },
      {
        text: "The image does not correspond to the company's terms and conditions",
        value: "The image does not correspond to the company's terms and conditions"
      },
      {
        text: 'Contact information is incorrect',
        value: 'Contact information is incorrect'
      },
      {
        text: 'Other',
        value: 'Other'
      }
    ],
    callback: function (result) {
      if (result != null) {
        if (result === 'Other') {
          result = $('#banothertext').val()
        }

        blockButtons()

        $.ajax({
          url: '/admin/photostatus',
          type: 'post',
          dataType: 'json',
          data: { photoid: photoid, status: 0, bantext: result },
          success: () => {
            document.location.reload(true)
          }
        })
      }
    }
  })

  dialog.init(function () {
    dialog
      .find('.bootbox-form')
      .append('<textarea class="form-control" id="banothertext" style="margin-top:20px" rows="4" cols="40"></textarea>')
  })
}

function pictureban(photoid) {
  const dialog = bootbox.prompt({
    title: ' Ban voucher',
    message: '<p>Please select an option below:</p>',
    inputType: 'radio',
    inputOptions: [
      {
        text: 'Does not meet company conditions',
        value: 'Does not meet company conditions'
      },
      {
        text: "The image does not correspond to the company's terms and conditions",
        value: "The image does not correspond to the company's terms and conditions"
      },
      {
        text: 'Contact information is incorrect',
        value: 'Contact information is incorrect'
      },
      {
        text: 'Other',
        value: 'Other'
      }
    ],
    callback: function (result) {
      if (result != null) {
        if (result === 'Other') {
          result = $('#banothertext').val()
        }

        blockButtons()

        $.ajax({
          url: '/admin/photostatus',
          type: 'post',
          dataType: 'json',
          data: { photoid: photoid, status: 3, bantext: result },
          success: () => {
            document.location.reload(true)
          }
        })
      }
    }
  })

  dialog.init(function () {
    dialog
      .find('.bootbox-form')
      .append('<textarea class="form-control" id="banothertext" style="margin-top:20px" rows="4" cols="40"></textarea>')
  })
}

function showimagemodal(url) {
  $('.imagepreview').attr('src', url)
  $('#imagemodal').modal('show')
}

function postdelete(postid) {
  const dialog = bootbox.prompt({
    title: 'Delete post',
    message: '<p>Please select an option below:</p>',
    inputType: 'radio',
    inputOptions: [
      {
        text: 'Does not meet company conditions',
        value: 'Does not meet company conditions'
      },
      {
        text: "The post does not correspond to the company's terms and conditions",
        value: "The post does not correspond to the company's terms and conditions"
      },
      {
        text: 'Other',
        value: 'Other'
      }
    ],
    callback: function (result) {
      if (result != null) {
        if (result === 'Other') {
          result = $('#banothertext').val()
        }

        blockButtons()

        $.ajax({
          url: '/admin/poststatus',
          type: 'post',
          dataType: 'json',
          data: { postid, status: POST_STATUS_DELETED, bantext: result },
          success: () => {
            document.location.reload(true)
          }
        })
      }
    }
  })

  dialog.init(function () {
    dialog
      .find('.bootbox-form')
      .append('<textarea class="form-control" id="banothertext" style="margin-top:20px" rows="4" cols="40"></textarea>')
  })
}

function postaccept(postid) {
  blockButtons()

  $.ajax({
    url: '/admin/poststatus',
    type: 'post',
    dataType: 'json',
    data: { postid, status: POST_STATUS_APPROVED },
    success: () => {
      document.location.reload(true)
    }
  })
}

window.picturefeature = picturefeature
window.pictureready = pictureready
window.picturereadyall = picturereadyall
window.setAcceptance = setAcceptance
window.manuallyConfirm = manuallyConfirm
window.picturedelete = picturedelete
window.pictureban = pictureban
window.showimagemodal = showimagemodal
window.postaccept = postaccept
window.postdelete = postdelete
