/* global i18n, notify,regenerate */

import $ from 'jquery'
import { baseUrl } from '../../../common/constants.js'

$('#vsetprofile').on('submit', function (e) {
  console.log('SUBMITOLTAM')
  e.preventDefault()

  const form = $(this)
  const modalButton = form.find('.modal-button')

  modalButton.prop('disabled', true)

  const data = form.serialize()

  $.ajax({
    url: `${baseUrl}/profile/vupload`,
    type: 'post',
    data: data,
    success: () => {
      form.get(0).reset()
      $('.slim-btn-remove').trigger('click')
      modalButton.prop('disabled', false)
      notify(i18n.t('global_uploadvoucher_success'), i18n.t('global_uploadvoucher_success_text'), 'success')
      regenerate(true)
    },
    error: (jqxhr) => {
      modalButton.prop('disabled', false)

      const response = JSON.parse(jqxhr.responseText)
      const errorMessage = response.messages.error

      notify(i18n.t('global_uploadvoucher_error'), errorMessage, 'fail')
    }
  })
})
