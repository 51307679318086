/* global i18n, notify */

import $ from 'jquery'
import { baseUrl } from '../../../common/constants.js'

$('#sendinviteletter').on('submit', function (e) {
  e.preventDefault()
  const form = $(this)
  const modalButton = form.find('.modal-button')

  modalButton.prop('disabled', true)

  const data = form.serialize()

  $.ajax({
    url: `${baseUrl}/profile/sendinviteletter`,
    type: 'post',
    data: data,
    success: (response) => {
      console.log(response)
      form.get(0).reset()
      $('.slim-btn-remove').trigger('click')
      modalButton.prop('disabled', false)
      notify(i18n.t('global_sendletter_success'), i18n.t('global_sendletter_success_text'), 'success')
      if ('data' in response) {
        $('#invite-letters-table tr:first').after(
          '<tr><td>' +
            response.data.id +
            '</td><td>' +
            response.data.created_at +
            '</td><td>' +
            response.data.toemail +
            '</td><td>' +
            response.data.toname +
            '</td><td>' +
            response.data.language +
            '</td></tr>'
        )
      }
    },
    error: (jqxhr) => {
      modalButton.prop('disabled', false)

      const response = JSON.parse(jqxhr.responseText)
      const errorMessage = Object.keys(response.messages)
        .map(function (x) {
          return response.messages[x].message
        })
        .join('<br>')

      notify(i18n.t('global_sendletter_error'), errorMessage, 'error')
    }
  })
})
