/* eslint-disable prettier/prettier */
/* eslint-disable linebreak-style */
/* global i18n, notify, isLoggedin */

import $ from 'jquery'
import { baseUrl } from '../../../common/constants.js'
import { formToJSON, xhrGet, xhrPatch, xhrDelete } from '../../../helpers/http.js'

const enableReferralSearch = () => {
  $('#referalid').prop('readonly', false)
  $('#referral-search').removeClass('hidden')
  $('#usereferalid-info-box').addClass('hidden')
}
const disableReferralSearch = () => {
  $('#referalid').prop('readonly', true)
  $('#referral-search').addClass('hidden')
  $('#usereferalid-info-box').removeClass('hidden')
}

const doYouHaveASponsorCheckbox = $('#usereferalid')

if (doYouHaveASponsorCheckbox.length) {
  const hasReferralIDChecked = doYouHaveASponsorCheckbox.val() === 'on'
  if (!hasReferralIDChecked) {
    enableReferralSearch()
  } else {
    disableReferralSearch()
  }

  doYouHaveASponsorCheckbox.change((event) => {
    if (!event.target.checked) {
      enableReferralSearch()
    } else {
      disableReferralSearch()
      hideReferralError()
      hideReferralPreview()
      $('#referalid').val('')
    }
  })
}

const updateReferralPreview = (userData) => {
  const preview = document.getElementById('referral-preview')

  const userId = userData.userid
  const username = userData.username

  const inputElement = document.getElementById('referalid')
  inputElement.value = userId

  const avatarElement = preview.querySelector('.avatarimage')
  avatarElement.src = userData.avatarimage

  const usernameElement = preview.querySelector('.username')
  usernameElement.textContent = username
}

const showReferralPreview = () => {
if (isLoggedin)
  {document.getElementById('send-message-to-referral').classList.remove('hidden')}
  document.getElementById('referral-preview').classList.remove('hidden')

}

const hideReferralPreview = () => {
  document.getElementById('send-message-to-referral').classList.add('hidden')
  document.getElementById('referral-preview').classList.add('hidden')

}

const showReferralError = (errorMessage) => {
  const referralError = document.getElementById('referral-error')
  if (referralError) {
    referralError.innerText = errorMessage
    referralError.classList.remove('hidden')
  }
}

const hideReferralError = () => {
  const referralError = document.getElementById('referral-error')
  referralError.classList.add('hidden')
}

const changeReferral = async () => {
  const customerId = window.__initial_data__.user.customerId
  const userId = parseInt(document.getElementById('referalid')?.value.trim() || -1)

  /*
  if (customerId === null) {
    hideReferralError()
    hideReferralPreview()
    return
  }
*/

  if (isNaN(userId)) {
    hideReferralPreview()
    showReferralError(i18n.t('user_not_found'))
    return
  }

  if (customerId === userId) {
    showReferralError(i18n.t('self_referral_error'))
    hideReferralPreview()
    return
  }

  try {
    const userData = await xhrGet(`${baseUrl}/web/user/${userId}`)
    hideReferralError()
    updateReferralPreview(userData)
    showReferralPreview()
  } catch (e) {
    hideReferralPreview()
    showReferralError(i18n.t('user_not_found'))
  }
}

if (!doYouHaveASponsorCheckbox.length) {
  changeReferral()
}

const changePasswordForm = document.getElementById('profile-change-password')

const getPasswordFields = () => {
  const fields = Array.from(changePasswordForm.querySelectorAll('input[type="password"]'))
  const data = fields.reduce((acc, field) => {
    acc[field.getAttribute('name')] = field.value
    return acc
  }, {})
  return JSON.stringify(data)
}


if (isLoggedin)
{
const changePasswordFormSubmit = changePasswordForm.querySelector('[type="submit"]')

const initialPasswordFieldValues = getPasswordFields()
changePasswordFormSubmit.setAttribute('disabled', 'disabled')
changePasswordFormSubmit.classList.add('disabled')

changePasswordForm.querySelectorAll('input[type="password"]').forEach((input) => {
  input.addEventListener('input', () => {
    if (initialPasswordFieldValues === getPasswordFields()) {
      changePasswordFormSubmit.setAttribute('disabled', 'disabled')
      changePasswordFormSubmit.classList.add('disabled')
    } else {
      changePasswordFormSubmit.removeAttribute('disabled')
      changePasswordFormSubmit.classList.remove('disabled')
    }
  })
})

changePasswordForm.addEventListener('submit', async (e) => {
  e.preventDefault()

  changePasswordFormSubmit.setAttribute('disabled', 'disabled')
  changePasswordFormSubmit.classList.add('disabled')

  try {
    await xhrPatch(`${baseUrl}/web/profile/change-password`, formToJSON(changePasswordForm))
    notify(i18n.t('change_password_notification_title'), i18n.t('change_password_success'), 'success')
  } catch (e) {
    const response = await e.json()

    response.messages.errors.forEach(({ field, message }) => {
      changePasswordForm.querySelector(`[name="${field}"]`).classList.add('is-invalid')
      changePasswordForm
        .querySelector(`[name="${field}"]`)
        .parentNode.querySelector('.smalllabel')
        .classList.add('is-invalid')
      notify(i18n.t('change_password_notification_title'), i18n.t(message.key, message.params), 'error')
    })
  }

  changePasswordFormSubmit.removeAttribute('disabled')
  changePasswordFormSubmit.classList.remove('disabled')
})

Array.from(changePasswordForm.querySelectorAll(`[name]`)).forEach((element) => {
  element.addEventListener('focus', () => {
    element.classList.remove('is-invalid')
    element.parentNode.querySelector('.smalllabel').classList.remove('is-invalid')
  })
})
}

async function deleteProfileAction (buttonInstance, isConfirmed) {
  if (isConfirmed) {
    $(buttonInstance).attr('disabled', true)
    $(buttonInstance).addClass('d-flex align-items-center')
    $(buttonInstance).html($(buttonInstance).html() + `<span class="spinner-grow spinner-grow-sm ml-4" role="status" aria-hidden="true"></span>`)


    try {
      await xhrDelete(`${baseUrl}/web/profile`)
      notify(i18n.t('profile_deleted_notification_title'), i18n.t('change_password_success'), 'success')
      window.location.replace(window.location.origin + '/allvouchers/latest');
    } catch (e) {
      const response = await e.json()
      notify(i18n.t('profile_deleted_notification_title'), i18n.t(response.mesasge), 'error')
    }
  }
}

window.changeReferral = changeReferral
window.deleteProfileAction = deleteProfileAction
