/* global i18n, notify */

import $ from 'jquery'

const forgotPasswordForm = document.getElementById('forgotform')

if (forgotPasswordForm) {
  const showLoading = () => {
    forgotPasswordForm.querySelector('.loading').classList.remove('hidden')
  }
  const hideLoading = () => {
    forgotPasswordForm.querySelector('.loading').classList.add('hidden')
  }

  $('#forgotform').on('submit', (event) => {
    event.preventDefault()

    const datastring = $('#forgotform').serialize()
    showLoading()

    $.ajax({
      url: '/forgotpassword',
      type: 'post',
      data: datastring,
      success: () => {
        hideLoading()
        notify(i18n.t('popup_title_password_reminder'), i18n.t('popup_mail_sent'), 'info')
      },
      error: (xhr) => {
        hideLoading()
        notify(i18n.t('popup_title_password_reminder'), xhr.responseJSON.messages.error, 'error')
      }
    })
  })
}
