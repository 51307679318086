import $ from 'jquery'

$('#yourusers-sector-filter').on('change', (e) => {
  const selectedSector = e.target.value
  document.querySelectorAll('.weboffice-user').forEach((user) => {
    if (selectedSector === 'all') {
      user.parentNode.classList.remove('hidden')
      return
    }

    if (user.getAttribute('data-has-all-the-sectors') === '0') {
      const currentSector = parseInt(user.getAttribute('data-sector'))
      if (currentSector >= selectedSector) {
        user.parentNode.classList.remove('hidden')
      } else {
        user.parentNode.classList.add('hidden')
      }
    }
  })
})

$('#yourusers-level-filter').on('change', (e) => {
  const selectedLevel = e.target.value
  document.querySelectorAll('.weboffice-user').forEach((user) => {
    if (selectedLevel === 'all') {
      user.parentNode.classList.remove('hidden')
      document.getElementById('myusers-tree').classList.remove('force-open')
      return
    }
    document.getElementById('myusers-tree').classList.add('force-open')
    const currentLevel = parseInt(user.getAttribute('data-level'))
    if (currentLevel >= selectedLevel) {
      user.parentNode.classList.remove('hidden')
    } else {
      user.parentNode.classList.add('hidden')
    }
  })
})

let previousSearch = ''
$('#yourusers-search-user [type="search"]').on('keyup', (e) => {
  e.preventDefault()
  const search = e.target.value.toLocaleLowerCase()
  if (search !== previousSearch) {
    previousSearch = search
    document.querySelectorAll('.weboffice-user').forEach((user) => {
      if (search === '') {
        user.parentNode.classList.remove('hidden')
        return
      }
      const id = user.getAttribute('data-userid')
      const username = user.getAttribute('data-username').toLocaleLowerCase()

      if (id === search || username === search) {
        user.parentNode.classList.remove('hidden')
        return
      }

      if (id.includes(search) || username.includes(search)) {
        user.parentNode.classList.remove('hidden')
        return
      }

      user.parentNode.classList.add('hidden')
    })
  }
})
