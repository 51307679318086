/* global i18n */

import $ from 'jquery'
import { baseUrl } from '../../common/constants.js'

const viewliked = (photoId) => {
  $('#photoModal .modal-title').text(i18n.t('photo_users_who_liked'))
  $('#resdata').html(i18n.t('loading') + '&hellip;')
  $('#photoModal').modal('show')
  $.ajax({
    url: `${baseUrl}/web/photo/${photoId}/likers`,
    type: 'get',
    dataType: 'json',
    success: function (data) {
      let append = '<div class="d-flex" style="flex-direction: row;  flex-wrap: wrap;justify-content: center;">'

      data.forEach((element) => {
        append += '<div class=" famousitem" style="margin-right:10px">'
        append +=
          '<a href="' +
          baseUrl +
          '/user/' +
          element.userid +
          '"><img class="avatarimage" src="' +
          element.avatarimage +
          '" alt="UserAvatar"></a>'
        append += '<div style="font-weight: 600"><span>' + element.username + '</span></div>'
        append += '</div>'
      })

      append += '</div>'

      $('#resdata').html(append)
    },
    error: function (request) {
      $('#resdata').html(request.responseJSON?.messages[0].message || request.statusText)
    }
  })
}

const viewdisliked = (photoId) => {
  $('#photoModal .modal-title').text(i18n.t('photo_users_who_disliked'))
  $('#resdata').html(i18n.t('loading') + '&hellip;')
  $('#photoModal').modal('show')
  $.ajax({
    url: `${baseUrl}/web/photo/${photoId}/dislikers`,
    type: 'get',
    dataType: 'json',
    success: function (data) {
      let append = '<div class="d-flex" style="flex-direction: row;  flex-wrap: wrap;justify-content: center;">'

      data.forEach((element) => {
        append += '<div class=" famousitem" style="margin-right:10px">'
        append +=
          '<a href="' +
          baseUrl +
          '/user/' +
          element.userid +
          '"><img class="avatarimage" src="' +
          element.avatarimage +
          '" alt="UserAvatar"></a>'
        append += '<div style="font-weight: 600"><span>' + element.username + '</span></div>'
        append += '</div>'
      })

      append += '</div>'

      $('#resdata').html(append)
    },
    error: function (request) {
      $('#resdata').html(request.responseJSON?.messages[0].message || request.statusText)
    }
  })
}

const viewfavorited = (photoId) => {
  $('#photoModal .modal-title').text(i18n.t('photo_users_who_favorited'))
  $('#resdata').html(i18n.t('loading') + '&hellip;')
  $('#photoModal').modal('show')
  $.ajax({
    url: `${baseUrl}/web/photo/${photoId}/favoriters`,
    type: 'get',
    dataType: 'json',
    success: function (data) {
      let append = '<div class="d-flex" style="flex-direction: row;  flex-wrap: wrap;justify-content: center;">'

      data.forEach((element) => {
        append += '<div class=" famousitem" style="margin-right:10px">'
        append +=
          '<a href="' +
          location.protocol +
          '//' +
          location.host +
          '/user/' +
          element.userid +
          '"><img class="avatarimage" src="' +
          element.avatarimage +
          '" alt="UserAvatar"></a>'
        append += '<div style="font-weight: 600"><span>' + element.username + '</span></div>'
        append += '</div>'
      })

      append += '</div>'

      $('#resdata').html(append)
    },
    error: function (request) {
      $('#resdata').html(request.responseJSON.messages[0].message)
    }
  })
}

window.viewfavorited = viewfavorited
window.viewliked = viewliked
window.viewdisliked = viewdisliked
