/* global $, xhrGet, baseUrl, i18n, formatNumber */
/* global compose, sum, values, nth, toPairs, join, map, reverse, sortBy, forEach */

const sumCityValues = compose(sum, values)

const isAdmin = location.pathname.includes('admin')

;(async () => {
  const countries = await xhrGet(
    isAdmin ? `${baseUrl}/admin/api/country/statistics` : `${baseUrl}/web/country/statistics`,
    {
      from: $('[name="start-date"]')[0].value,
      to: $('[name="end-date"]')[0].value
    }
  )

  const sortedCountries = compose(reverse, sortBy(compose(sumCityValues, nth(1))), toPairs)(countries)

  forEach(([code, { name, cities }]) => {
    $('.countries').append(`
    <div class="country-counter" data-country-code="${code}" title="${name}">
      ${code.toUpperCase()}<br />
      <img src="${baseUrl}/assets/flags/${code.toLowerCase()}.svg" alt="" style="width: auto; height: 24px;" /><br />
      ${formatNumber(sumCityValues(cities), ' ')}
    </div>
    `)
  }, sortedCountries)

  $('.country-counter').on('click', (e) => {
    e.stopPropagation()

    $('.country-counter.selected').removeClass('selected')

    const clickedElement = $(e.target).closest('.country-counter')
    clickedElement.addClass('selected')

    const countryCode = clickedElement.data('country-code')
    const countryName = countries[countryCode].name
    const cities = countries[countryCode].cities

    const domListOfCities = compose(
      join(''),
      map(([city, userCount]) => `<b>${city}</b> ${formatNumber(userCount, ' ')}<br />`),
      reverse,
      sortBy(nth(1)),
      toPairs
    )(cities)

    $('.cities').html(`
    <div>
      <h5>${i18n.t('office_statistics_country')} / ${i18n.t('global_users')}</h5>
      <b>${countryName}</b> ${formatNumber(sumCityValues(cities), ' ')}
      <hr />
      ${domListOfCities}
    </div>
    `)
  })

  $('.country-counter:first').trigger('click')
})()
