/* global i18n, notify */

import $ from 'jquery'

const resendForm = document.getElementById('resendform')

console.log(resendForm, $('#resendform'))

if (resendForm) {
  const showLoading = () => {
    resendForm.querySelector('.loading').classList.remove('hidden')
  }
  const hideLoading = () => {
    resendForm.querySelector('.loading').classList.add('hidden')
  }

  $('#resendform').on('submit', (event) => {
    event.preventDefault()

    const datastring = $('#resendform').serialize()
    showLoading()

    $.ajax({
      url: '/resend-confirmation-email',
      type: 'post',
      data: datastring,
      success: () => {
        hideLoading()
        notify(i18n.t('popup_title_confirmation_reminder'), i18n.t('popup_mail_sent_confirmation'), 'info')
      },
      error: (xhr) => {
        hideLoading()
        notify(i18n.t('popup_title_confirmation_reminder'), xhr.responseJSON.messages.error, 'error')
      }
    })
  })
}
