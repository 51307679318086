import { compose, replace, trim, isEmpty, map, uniq, flatten } from 'ramda'
import { xhrGet, xhrPatch, formToJSON } from '../../../helpers/http.js'
import { baseUrl } from '../../../common/constants.js'
import { showFullpageLoading, hideFullpageLoading } from '../common.js'

const languages = ['en', 'hu', 'ru', 'es', 'pt', 'kr', 'tr', 'cn']
const dictionary = {}

const unescapeValue = compose(replace(/\\n/g, '\n'), replace(/\r?\n/g, '\\n'), trim)

const escapeValue = compose(replace(/\r?\n/g, '\\n'), trim)

const asyncForEach = async (fn, data) => {
  await Promise.all(data.map(fn))
}

const loadLanguages = async () => {
  await asyncForEach(async (language) => {
    dictionary[language] = await xhrGet(`${baseUrl}/web/i18n/${language}`)
  }, languages)
}

const patchDictionary = async (language, changes) => {
  return await xhrPatch(`${baseUrl}/admin/api/translation/${language}`, changes)
}

const diffObjectValues = (oldData, newData) => {
  return Object.keys(newData).reduce((diff, key) => {
    const valuesDiffer = oldData[key] !== newData[key]
    const valueIsEmpty = isEmpty(newData[key])

    if (valuesDiffer && !valueIsEmpty) {
      diff[key] = trim(newData[key])
    }

    return diff
  }, {})
}

const onSave = async (form) => {
  const formData = formToJSON(form)

  const diffs = {}
  languages.forEach((language) => {
    const diff = diffObjectValues(dictionary[language], formData[language])
    if (!isEmpty(diff)) {
      diffs[language] = map(escapeValue, diff)
    }
  })

  console.log('sending patch:', diffs)

  try {
    await asyncForEach(async ([language, changes]) => {
      dictionary[language] = await patchDictionary(language, changes)
    }, Object.entries(diffs))

    console.log('dictionary patched successfully')
  } catch (e) {
    console.error('dictionary patching failed, because:', await e.json())
  }
}

const getDictionaryKeys = () => {
  const keysPerLanguage = languages.map((language) => {
    return Object.keys(dictionary[language])
  })

  return uniq(flatten(keysPerLanguage))
}

const generateForm = () => {
  const tbody = document.querySelector('#translations tbody')
  const keys = getDictionaryKeys()

  const generateRow = (key) => {
    const columns = [
      `<td>${key}</td>`,
      ...languages.map((language) => {
        const value = unescapeValue(dictionary[language][key] || '')
        return `<td><textarea name="${language}[${key}]">${value}</textarea></td>`
      })
    ]
    return `<tr>${columns.join('')}</tr>`
  }

  const rows = keys.map(generateRow)

  tbody.innerHTML = rows.join('')
}

const addEmptyRow = (key) => {
  const tbody = document.querySelector('#translations tbody')

  const generateRow = (key) => {
    const columns = [
      `<td>${key}</td>`,
      ...languages.map((language) => {
        return `<td><textarea name="${language}[${key}]"></textarea></td>`
      })
    ]
    const tmp = document.createElement('template')
    tmp.innerHTML = `<tr>${columns.join('')}</tr>`
    return tmp.content
  }

  tbody.appendChild(generateRow(key))
}

;(async () => {
  showFullpageLoading()
  await loadLanguages()
  generateForm()
  hideFullpageLoading()

  document.querySelector('#translations form').addEventListener('submit', async (e) => {
    e.preventDefault()
    showFullpageLoading()
    await onSave(e.target)
    generateForm()
    hideFullpageLoading()
  })

  Array.from(document.querySelectorAll('#translations .add-new-row')).forEach((button) => {
    button.addEventListener('click', () => {
      let key = prompt('Please enter a new dictionary key:')
      if (key !== null) {
        key = key.trim()
        if (key === '') {
          alert('Key is empty!')
        } else {
          const keyExists = dictionary.en[key]
          if (!keyExists) {
            console.log('creating new key', key)
            addEmptyRow(key)
          } else {
            alert('Key already exists!')
          }
        }
      }
    })
  })
})()
